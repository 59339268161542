<template>
    <main>
        <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
        <page-header-compact>Evoluciones </page-header-compact>
        <div class="container-xl px-4">
            <div class="card mt-2">
                <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
            </div>
            <div class="card mt-2">
                <div class="card-body">
                    <urgenciaEvolucion :idHistoria="idHistoria"></urgenciaEvolucion>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import urgenciaEvolucion from './urgenciaEvolucion';
import modalInformacionUsuario from "../informacionUsuario/modalInformacionUsuario";
import cardInformacionUsuario from "../informacionUsuario/cardInformacionUsuario";
import historiaClinicaService from '../../../../services/historiaClinicaService';
import PageHeaderCompact from "../../../../components/layouts/content/page-header-compact";

export default {
    components: {urgenciaEvolucion,modalInformacionUsuario,cardInformacionUsuario,PageHeaderCompact},
    data(){
        return {
            idHistoria:'',
            usuario: {},
        }
    },
    methods: {
        async cargarUsuario(id) {
            this.LoaderSpinnerShow();
            const response = await historiaClinicaService.show(id);
            this.usuario = response.data.usuario;
            this.LoaderSpinnerHide();
        },
    },
    created() {
        
        this.idHistoria = this.$route.params.id_historia;
        this.cargarUsuario(this.idHistoria);
        

    },
}
</script>